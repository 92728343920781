<template>
  <div style="padding: 10px; background-color: white;">
    <LoadingPlaceholder v-if="reportData" />
    <template v-if="reportData">
      <div class="progress mt-3">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{width: reportData.percentage + '%'}"
          :aria-valuenow="reportData.percentage"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ reportData.percentage + '%' }}
        </div>
      </div>
    </template>
    <div v-if="employeeResult && employeeResult.length > 0">
      <div class="row mb-2">
        <span
          class="col-12"
          style="font-size: 13px"
        >
          {{ $t("worklogCustomer.billableSupportActivities") }}
          {{ dateTime_date(selectedDateFrom) }} -
          {{ dateTime_date(selectedDateTo) }}
        </span>
      </div>
      <div class="row mb-4">
        <span
          class="col-12"
          style="font-size: 13px"
        >
          {{ $t("worklogCustomer.infoBill") }}:
          <a
            class="alt-primary-color"
            target="_blank"
            href="https://help.alturos.com/support/solutions/articles/29000040473"
          >https://help.alturos.com/support/solutions/articles/29000040473</a>
        </span>
      </div>
      <div class="mb-4">
        <Grid
          :ref="kgm_ref"
          :style="{ height: 'auto' }"
          :data-items="kgm_computedGridItems(employeeResult)"
          :columns="kgm_responsiveColumns()"
          :filterable="true"
          :filter="kgm_filter"
          :pageable="kgm_pagable"
          :page-size="kgm_take"
          :skip="kgm_skip"
          :take="kgm_take"
          :total="kgm_allGridItems(employeeResult)"
          :sortable="{
            allowUnsort: kgm_allowUnsort,
            mode: kgm_sortMode,
          }"
          :sort="kgm_sort"
          selected-field="selected"
          @rowclick="kgm_gridOnRowClick"
          @filterchange="kgm_gridFilterChange"
          @pagechange="kgm_gridPageChange"
          @sortchange="kgm_gridSortChange"
        >
          <template
            slot="floatTemplate"
            slot-scope="{ props }"
          >
            <td>
              <span>{{
                kgm_getNestedValue(props.field, props.dataItem)
                  .toFixed(2)
                  .replace(".", ",")
              }}</span>
            </td>
          </template>
          <template
            slot="percentageTemplate"
            slot-scope="{ props }"
          >
            <td>
              <span>{{
                kgm_getNestedValue(props.field, props.dataItem)
                  .toFixed(2)
                  .replace(".", ",")
              }}%</span>
            </td>
          </template>
          <template
            slot="nameTemplate"
            slot-scope="{ props }"
          >
            <td>
              <span
                style="cursor: pointer"
                @click="scrollInto(`unq-${props.dataItem.employee}-${props.dataItem.totalInclAbsencesHours}`)"
              >{{ props.dataItem.employee }}</span>
            </td>
          </template>
          <div
            slot="cellFilter"
            slot-scope="{ props, methods }"
            class="input-group"
          >
            <input
              ref="cellFilter"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a value"
              @input="
                (event) => {
                  methods.change({
                    operator: 'contains',
                    field: props.field,
                    value: event.target.value,
                    syntheticEvent: event,
                  });
                }
              "
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="
                  kgm_resetInput('cellFilter', (event) => {
                    methods.change({
                      operator: '',
                      field: '',
                      value: '',
                      syntheticEvent: event,
                    });
                  })
                "
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
        </Grid>
        <div class="row mt-1">
          <span class="col-9" />
          <div
            class="row col-3"
            style="padding-right: 0; font-size: 15px"
          >
            <span class="col-8">
              <b>TOTAL SUPPORT</b>
            </span>
            <span
              class="col-4"
              style="text-align: end"
            >
              <b>{{ supportSum }} h</b>
            </span>
          </div>
        </div>
        <div class="row mt-1">
          <span class="col-9" />
          <div
            class="row col-3"
            style="padding-right: 0; font-size: 15px"
          >
            <span class="col-8">
              <b>TOTAL PROJECT</b>
            </span>
            <span
              class="col-4"
              style="text-align: end"
            >
              <b>{{ projectSum }} h</b>
            </span>
          </div>
        </div>
        <div class="row mt-1">
          <span class="col-9" />
          <div
            class="row col-3"
            style="padding-right: 0; font-size: 15px"
          >
            <span class="col-8">
              <b>TOTAL</b>
            </span>
            <span
              class="col-4"
              style="text-align: end"
            >
              <b>{{ billableSum }} h</b>
            </span>
          </div>
        </div>
      </div>
      
      <div
        v-for="(employee, index) in employeeResult"
        :id="`unq-${employee.employee}-${employee.totalInclAbsencesHours}`" 
        :key="`employee-${ index }`"
        style="font-size: 17px;"
        class="mb-3"
      >
        <div class="row">
          <span
            class="col-12"
            style="font-size: 20px;"
          >
            <b>{{ employee.employee }}</b>
            <font-awesome-icon
              class="ml-3"
              icon="arrow-up"
              style="cursor: pointer;"
              @click="scrollToTop" 
            />
          </span>
        </div>
          
        <div
          v-for="counter in 3"
          :key="counter"
        >
          <template v-if="counter === 1 && employee.projectAndSupportComponents.length">
            <p style="color:red; font-weight: bold;">
              Support or project hours
            </p>
          </template>
          <template v-if="counter === 2 && employee.nonProjectAndSupportComponents.length">
            <p style="color:red; font-weight: bold;">
              Non support or project hours
            </p>
          </template>
          <template v-if="counter === 3 && employee.absences.length">
            <p style="color:red; font-weight: bold;">
              Absences
            </p>
          </template>
          <template
            v-if="(counter === 1 && employee.projectAndSupportComponents.length) ||
              (counter === 2 && employee.nonProjectAndSupportComponents.length) || (counter === 3 && employee.absences.length)"
          >
            <div class="row">
              <span
                class="col-1"
                style="font-size: 17px;"
              >
                <b>{{ $t('component') }}</b>
              </span>
              <span
                class="col-11"
                style="font-size: 17px; text-align: end;"
              >
                <b>{{ $t('worklogCustomer.effort') }}</b>
              </span>
            </div>

            <div
              class="row mb-3"
              style="border-bottom: 1px solid black;"
            >
              <span
                class="col-1"
                style="font-size: 14px;"
              >
                FD Reporter
              </span>
              <span
                class="col-1"
                style="font-size: 14px;"
              >
                {{ $t('worklogCustomer.date') }}
              </span>
              <span
                class="col-4"
                style="font-size: 14px;"
              >
                {{ $t('worklogCustomer.task') }}
              </span>
              <span
                class="col-1"
                style="font-size: 14px;"
              >
                JIRA Issue
              </span>
              <span
                class="col-2"
                style="font-size: 14px;"
              >
                {{ $t('worklogCustomer.summary') }}
              </span>
              <span
                class="col-1"
                style="font-size: 14px;"
              >
                {{ $t('worklogCustomer.resolution') }}
              </span>
              <span
                class="col-1"
                style="font-size: 14px;"
              >
                {{ $t('worklogCustomer.billingReason') }}
              </span>            
            </div>
              
            <div
              v-for="(component, componentIndex) in counter == 1 ? employee.projectAndSupportComponents :
                counter == 2 ? employee.nonProjectAndSupportComponents : employee.absences"
              :key="`comp-${counter}-${index}-${ componentIndex }`" 
              style="font-size: 15px;"
            >
              <div
                class="row"
              >
                <span class="col-10">
                  <b>{{ component.component }}</b>
                </span>
                <span
                  class="col-2"
                  style="text-align: end;"
                >
                  <b>{{ component.effort }}</b>
                </span>
              </div>
              <div
                v-for="(issue, issueIndex) in component.issues"
                :key="`usrp-${counter}-${index}-${ componentIndex }-${ issueIndex }`" 
                style="font-size: 13px"
              >
                <div
                  v-for="(worklog, worklogIndex) in issue.worklogs"
                  :key="`usrw-${counter}-${index}-${ componentIndex }-${ issueIndex }-${ worklogIndex }`" 
                  style="font-size: 13px"
                >
                  <div
                    class="row mb-2"
                  >
                    <span
                      v-if="issue.fdRequester && worklogIndex == 0"
                      class="col-1"
                    >
                      {{ issue.fdRequester }}
                    </span>
                    <span
                      v-else
                      class="col-1"
                    >
                      {{ worklog.requester }}
                    </span>
                    <span class="col-1">
                      {{ dateTime_date(worklog.started) }}
                    </span>
                    <span
                      v-if="authenticationHasRole('scope_staff')"
                      class="col-4"
                      style="white-space: pre-wrap;"
                      v-html="getFdTagComment(worklog.comment, worklog.fdType)"
                    />
                    <span
                      v-else
                      class="col-4"
                      style="white-space: pre-wrap;"
                      v-html="getFdTagComment(worklog.comment)"
                    />
                    <span class="col-1">
                      <a
                        v-if="worklogIndex == 0"
                        class="alt-primary-color"
                        target="_blank"
                        :href="jiraUrl(issue.key)"
                      ><b>{{ issue.key }}</b></a>
                    </span>
                    <span
                      v-if="worklogIndex == 0 && authenticationHasRole('scope_staff')"
                      class="col-2"
                      v-html="getFdTagSummary(issue.fields.summary, false, issue.fdType)"
                    />
                    <span
                      v-else-if="worklogIndex == 0"
                      class="col-2"
                      v-html="getFdTagSummary(issue.fields.summary, false)"
                    />
                    <span
                      v-else
                      class="col-2"
                    />
                    <span class="col-1">
                      <b v-if="worklogIndex == 0 && issue.fields.resolution">{{ issue.fields.resolution.name }}</b>
                    </span>
                    <span class="col-1">
                      <b v-if="worklogIndex == 0 && issue.fields.customfield_14700">{{ issue.fields.customfield_14700.value }}</b>
                    </span>
                    <span
                      class="col-1"
                      style="text-align: end;"
                    >
                      {{ (worklog.timeSpentSeconds / 3600).toFixed(2).replace('.', ',') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row mt-3"
            >
              <span
                class="col-10"
              />
              <div
                class="row col-2"
                style="padding-right: 0; font-size: 15px;"
              >
                <span class="col-8">
                  <b>TOTAL</b>
                </span>
                <span
                  class="col-4"
                  style="text-align: end;"
                >
                  <b v-if="counter === 1 && employee.projectAndSupportComponents">{{ employee.totalBillableHours }} h</b>
                  <b v-if="counter === 2 && employee.nonProjectAndSupportComponents">{{ employee.restHours }} h</b>
                  <b v-if="counter === 3 && employee.absences">{{ employee.absencesHours }} h</b>
                </span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <template v-else-if="employeeResult && employeeResult.length == 0">
      <div>
        {{ $t('noDataAvailable') }}
      </div>
    </template>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { mapGetters } from 'vuex';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import FreshdeskWidget from '../../plugins/FreshdeskWidget.js';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: 'CustomerWorklogReportPdf',
  mixins: [
    dateTimeMixin,
    urlParseMixin,
    authenticationMixin,
    kendoGridMixin
  ],
  props: {
    sComponent: {
      type: String,
      required: false,
      default: null
    },
    sStartDate: {
      type: String,
      required: false,
      default: null
    },
    sEndDate: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      selectedDateFrom: this.moment().subtract(1, 'months').startOf('month'),
      selectedDateTo: this.moment().subtract(1, 'months').endOf('month'),
      employeeResult: null,
      progress: 0,
      createExcel: true,
      exportLoading: false,
      reportInterval: null,
      reportData: null,
      kgm_take: 100,
      kgm_columns: [
        {
          field: "manager",
          filterable: true,
          filter: "text",
          title: "Manager",
          filterCell: "cellFilter",
        },
        {
          field: "employee",
          filterable: true,
          filter: "text",
          title: this.$t("worklogCustomer.employee"),
          filterCell: "cellFilter",
          cell: "nameTemplate",
        },
        {
          title: "Billable Hours",
          children: [
            {
              field: "supportHours",
              filterable: true,
              filter: "text",
              title: this.$t("worklogCustomer.effortSupport"),
              filterCell: "cellFilter",
              cell: "floatTemplate",
            },
            {
              field: "supportPercentage",
              filterable: true,
              filter: "text",
              title: "%",
              filterCell: "cellFilter",
              width: "80px",
              cell: "percentageTemplate",
            },
            {
              field: "projectHours",
              filterable: true,
              filter: "text",
              title: this.$t("worklogCustomer.effortProject"),
              filterCell: "cellFilter",
              cell: "floatTemplate",
            },
            {
              field: "projectPercentage",
              filterable: true,
              filter: "text",
              title: "%",
              filterCell: "cellFilter",
              width: "80px",
              cell: "percentageTemplate",
            },
            {
              field: "totalBillableHours",
              filterable: true,
              filter: "text",
              title: "Total",
              filterCell: "cellFilter",
              cell: "floatTemplate",
            },
            {
              field: "totalBillablePercentage",
              filterable: true,
              filter: "text",
              title: "%",
              filterCell: "cellFilter",
              width: "80px",
              cell: "percentageTemplate",
            },
          ],
        },
        {
          title: "Non-Billable Hours",
          children: [
            {
              field: "restHours",
              filterable: true,
              filter: "text",
              title: this.$t("worklogCustomer.effortRest"),
              filterCell: "cellFilter",
              cell: "floatTemplate",
            },
            {
              field: "restPercentage",
              filterable: true,
              filter: "text",
              title: "%",
              filterCell: "cellFilter",
              width: "80px",
              cell: "percentageTemplate",
            },
          ],
        },
        {
          field: "totalExclAbsencesHours",
          filterable: true,
          filter: "text",
          title: "Total excl. Absences",
          filterCell: "cellFilter",
          cell: "floatTemplate",
        },
        {
          field: "absencesHours",
          filterable: true,
          filter: "text",
          title: "Absences",
          filterCell: "cellFilter",
          cell: "floatTemplate",
        },
        {
          field: "totalInclAbsencesHours",
          filterable: true,
          filter: "text",
          title: "Total incl. Absences",
          filterCell: "cellFilter",
          cell: "floatTemplate",
        },
        {
          title: "Target Hours",
          children: [
            {
              field: "targetHours",
              filterable: true,
              filter: "text",
              title: "Target",
              filterCell: "cellFilter",
              cell: "floatTemplate",
            },
            {
              field: "deltaHours",
              filterable: true,
              filter: "text",
              title: "Delta",
              filterCell: "cellFilter",
              cell: "floatTemplate",
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl'
    ]),
    projectSum () {
      if (this.employeeResult) {
        return this.employeeResult.reduce(
          (acc, employee) => acc + employee.projectHours,
          0
        ).toFixed(2).replace('.', ',');
      }
      return 0
    },
    supportSum () {
      if (this.employeeResult) {
        return this.employeeResult.reduce(
          (acc, employee) => acc + employee.supportHours,
          0
        ).toFixed(2).replace('.', ',');
      }
      return 0
    },
    billableSum () {
      if (this.employeeResult) {
        return this.employeeResult.reduce(
          (acc, employee) => acc + employee.totalBillableHours,
          0
        ).toFixed(2).replace('.', ',');
      }
      return 0
    },
  },
  beforeDestroy () {
    FreshdeskWidget.show();
    this.cancelInterval();
  },
  created () {
    FreshdeskWidget.hide();
    if(this.sStartDate && this.sEndDate) {
      this.selectedDateFrom = this.sStartDate;
      this.selectedDateTo = this.sEndDate;
    }
    this.generateReport();
  },
  mounted () {
    var header = document.getElementById("m_header");
    header.remove();
    var menu = document.getElementsByClassName("mainMenu");
    for(var m of menu) {
      m.remove()
    }
    var main = document.getElementsByClassName("m-body");
    for(var d of main) {
      d.classList.add("npd");
      d.setAttribute("style","padding-top: 0px !important;")
    }
  },
  methods: {
    scrollInto (val) {
      document.getElementById(`unq-${val}`).scrollIntoView();
      window.scrollBy(0, -10);
    },
    scrollToTop () {
      window.scrollTo(0, 0);
    },
    getFdTagSummary (text, bold) {
      if(text.includes('[') && text.includes(']')) {
        let sIndex = text.indexOf('[');
        let eIndex = text.indexOf(']');
        let id = text.substring(sIndex, eIndex).replace('[', '').replace(']', '');
        if(!isNaN(parseInt(id)) && !id.includes(' ')) {
          let url = `https://alturos.freshdesk.com/a/tickets/${id}`;
          let anchor = `<a class="alt-primary-color" target="_blank" href="${url}">${id}</a>`;
          return bold ? `<b>${text.replace(id, anchor)}</b>` : `${text.replace(id, anchor)}`;
        }
        return bold ? `<b>${text}</b>` : text;  
      }
      return bold ? `<b>${text}</b>` : text;  
    },
    getFdTagComment (text) {
      if(text.includes('FD#')) {
        var tmpText = text.replace(" ", "");
        var startingIndices = [];
        var indexOccurence = tmpText.indexOf('FD#', 0);

        while(indexOccurence >= 0) {
            startingIndices.push(indexOccurence + 3);
            indexOccurence = tmpText.indexOf('FD#', indexOccurence + 1);
        }

        for(var indx of startingIndices) {
          var tmpId = ''; 
          for(let i = indx; i < tmpText.length; i++) {
            if(!isNaN(parseInt(tmpText[i]))) {
              tmpId += tmpText[i]
            }
            else {
              break;
            }
          }
          if(tmpId != '') {
            let url = `https://alturos.freshdesk.com/a/tickets/${tmpId}`;
            let anchor = `<a class="alt-primary-color" target="_blank" href="${url}">${tmpId}</a>`;
            text = text.replace(tmpId, anchor)
          }
        }
        return text;
      }
      
      return `${text}`;
    },
    jiraUrl (endpoint) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ endpoint }`);
    },
    createInterval () {
      this.reportData = { percentage: 0};
      this.reportInterval = window.setInterval(() => {
        this.getReportProgress();
      }, 2000);
    },
    cancelInterval () {
      this.reportData = null;
      window.clearInterval(this.reportInterval);
      this.reportInterval = null;
      this.reportData = null;
    },
    getReportProgress () {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      this.axios.get(`/Issue/GetEmployeeReportData?user=${email}`)
      .then((response) => {
        if (response.status == 200 && response.data) {
          this.reportData = response.data;
          if(this.reportData.value) {
            this.employeeResult = this.reportData.value;
            this.cancelInterval();
          }
          else if(this.reportData.error) {
            this.cancelInterval();
          }
        }
        else {
          this.reportData = null;
          this.cancelInterval();
        }
      })
    },
    async generateReport () {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      this.issueResult = {};
      var req = {
        startDate: this.selectedDateFrom,
        endDate: this.selectedDateTo,
        user: email
      };

      await this.axios.post(`/Issue/GetEmployeeWorklogReport`, req)
      .then(() => {
        this.createInterval();
      });
    }
  }
}
</script>

<style>
/* @media print {
  @page {size: A4 landscape}
    body {-moz-transform:rotate(-90deg) scale(.58,.58)}
    .row {
      page-break-inside:avoid; page-break-after:auto;
    }
    .hrs {
      white-space: nowrap;
    }
} */

@media print {
html,body{height:100%;width:100%;margin:0;padding:0;}
 @page {
	size: A4 landscape;
	max-height:100%;
	max-width:100%
	}
   .row {
      page-break-inside:avoid; page-break-after:auto;
    }
    .pb-a {
      page-break-inside:avoid; page-break-after:always;
    }
  .up-icon {
    display: none;
  }
}

.npd {
  padding: 0px !important;
  padding-top: 0px !important;
}
</style>